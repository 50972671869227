import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { stringIsNullOrEmpty, createMultiPartFormBody, isObjectEmpty } from '../../../common/util';
import { ApiKey, OrderStatus } from '../../../common/constant';
import { WebUrl } from '../../../routes';
import { useRouter } from '../../../common/useRouter';
import ApiEngine from '../../../common/api-engine';
import { Icon } from "@chakra-ui/react";
import {
    MdCheck,
    MdCancel
} from "react-icons/md";
import { Button, Space, Flex } from 'antd';
import { ToggleContent } from '../../../app.js';
import Routes from '../../../common/api_routes';
import { useDispatch } from 'react-redux';
import { showCustomDialog, setBusy, setIdle, } from "../../../application/action/app_action";

/// <summary>
/// Author: YJ
/// </summary>
const TransactionDirectCallback = () => {
    var _location = useLocation();
    var _dispatch = useDispatch();
    var _router = useRouter();
    const [txnState, setTxnState] = useState();
    const [goHome, setGoHome] = useState(true);
    const [redirectUrl, setRedirectUrl] = useState();
    const { toggleTopBar } = useContext(ToggleContent);

    useEffect(() => {
        if (toggleTopBar != null) {
            toggleTopBar(false);
        }

        return () => {
            toggleTopBar(true);
        }
    }, [toggleTopBar])

    useEffect(() => {
        (async () => {
            try {
                const params = new URLSearchParams(window.location.search);

                const data = {
                    skey: params.get('skey'),
                    tranID: params.get('tranID'),
                    amount: params.get('amount'),
                    domain: params.get('domain'),
                    status: params.get('status'),
                    currency: params.get('currency'),
                    paydate: params.get('paydate'),
                    orderid: params.get('orderid'),
                    appcode: params.get('appcode'),
                    error_code: params.get('error_code'),
                    error_desc: params.get('error_desc'),
                    channel: params.get('channel'),
                    extraPString: params.get('extraP')
                };
                
                if (
                    stringIsNullOrEmpty(data.skey)
                    ||
                    stringIsNullOrEmpty(data.tranID)
                    ||
                    stringIsNullOrEmpty(data.status)
                    ||
                    stringIsNullOrEmpty(data.orderid)
                    ||
                    stringIsNullOrEmpty(data.amount)
                ) {
                    throw 'Invalid session';
                }

                try {
                    const response = await ApiEngine.post(Routes._TRANSACTION_DIRECT_CALLBACK, createMultiPartFormBody(data), {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });

                    if (!response[ApiKey._API_SUCCESS_KEY]) {
                        throw response[ApiKey._API_MESSAGE_KEY];
                    }

                    if (!isObjectEmpty(response[ApiKey._API_DATA_KEY])) {
                        if (response[ApiKey._API_DATA_KEY]["status"] == OrderStatus._SUCCESS) {
                            setTxnState({ status: true, title: 'Success', message: "Payment success" });
                        }
                        else if (response[ApiKey._API_DATA_KEY]["status"] == OrderStatus._FAILED) {
                            setTxnState({ status: false, title: 'Error', message: "Payment failed" });
                        }

                        setGoHome(response[ApiKey._API_DATA_KEY]["goHome"]);
                        setRedirectUrl(response[ApiKey._API_DATA_KEY]["redirectUrl"]);
                    }
                    else {
                        setTxnState({ status: false, title: 'Error', message: "Payment error" });
                    }
                }
                catch (err) {
                    setTxnState({ status: false, title: 'Error', message: "Error" });
                }
            }
            catch (err) {
                console.error(err);
                _dispatch(showCustomDialog({
                    content: err
                }));
            }
        })();
    }, []);

    return (
        <>
            <div style={{ padding: '0 20px', height: '100%', margin: '0 auto', textAlign: 'center', flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Flex gap="middle" align='center' justify='center' wrap vertical>
                    {
                        !isObjectEmpty(txnState) && <>
                            {
                                txnState.status
                                    ?
                                    <Icon width={'150px'} height={'150px'} color={'green'} as={MdCheck} />
                                    :
                                    <Icon width={'150px'} height={'150px'} color={'red'} as={MdCancel} />
                            }
                            <h1>{txnState.message}</h1>
                            <Space direction='horizontal'>
                                {(!stringIsNullOrEmpty(redirectUrl) || goHome) && <Button type="primary"
                                    success
                                    onClick={() => {
                                        if (!stringIsNullOrEmpty(redirectUrl)) {
                                            window.location.href = redirectUrl;
                                        }
                                        else {
                                            _router.navigate(WebUrl._DEFAULT, { state: txnState, replace: true })
                                        }
                                    }}>
                                    Done
                                </Button>}

                            </Space>
                        </>
                    }
                </Flex>
            </div>
        </>
    );
};

export default TransactionDirectCallback;