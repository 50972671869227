import React from "react";
import { Role } from "./common/constant";
import { Icon } from "@chakra-ui/react";
import {
  MdHome
} from "react-icons/md";
import MainDashboard from "./views/admin/default";
import Home from "./views/non-auth/home";
import SignIn from "./views/non-auth/signIn";
import Register from "./views/non-auth/register";
import ForgotPassword from "./views/non-auth/forgot-password";
import ChangePassword from "./views/non-auth/change-password";
import Cart from "./views/auth/cart";
import PaymentProcess from "./views/auth/payment/payment-process";
// import PaymentResult from "./views/auth/payment/payment-result";
import TransactionInit from "./views/non-auth/transaction/transaction-init";
import TransactionCallback from "./views/non-auth/transaction/transaction-callback";
import TransactionDirectCallback from "views/non-auth/transaction/transaction-direct-callback";
import PaymentResult from "./views/auth/payment/payment-result";
import CheckOut from "./views/auth/payment/check-out";

/// <summary>
/// Author: -
/// </summary>
export class WebUrl {
  static _DEFAULT = '/';
  static _REGISTER = '/register';
  static _FORGOT_PASSWORD = '/forgot-password';
  static _CHANGE_PASSWORD = '/change-password';
  static _LOGIN = '/login';
  static _CART = '/cart';
  static _PAYMENT_PROCESS = '/payment-process/:txnId/:amount'; // temp page for process payment
  static _TRANSACTION_INIT = 'transaction/init';
  static _TRANSACTION_CALLBACK = '/transaction/callback/:orderId/:status/:amount/:currency';
  static _TRANSACTION_DIRECT_CALLBACK = '/transaction/direct/callback';
  static _PAYMENT_RESULT = '/payment-result/:orderId/:status/:amount/:currency';
  static _CHECK_OUT = '/check-out';
}

/// <summary>
/// Author: -
/// </summary>
export const _NON_AUTH_ROUTES = [
  {
    name: "LogoHouse",
    path: WebUrl._DEFAULT,
    component: <Home />,
  },
  {
    name: "SIGN_IN",
    path: WebUrl._LOGIN,
    component: <SignIn />,
  },
  {
    name: "REGISTER",
    path: WebUrl._REGISTER,
    component: <Register />,
  },
  {
    name: "FORGOT_PASSWORD",
    path: WebUrl._FORGOT_PASSWORD,
    component: <ForgotPassword />,
  },
  {
    name: "CHANGE_PASSWORD",
    path: WebUrl._CHANGE_PASSWORD,
    component: <ChangePassword />,
  },
  {
    name: "TRANSACTION_INIT",
    path: WebUrl._TRANSACTION_INIT,
    component: <TransactionInit />,
  },
  {
    name: "TRANSACTION_CALLBACK",
    path: WebUrl._TRANSACTION_CALLBACK,
    component: <TransactionCallback />,
  },
  {
    name: "TRANSACTION_CALLBACK",
    path: WebUrl._TRANSACTION_DIRECT_CALLBACK,
    component: <TransactionDirectCallback />,
  }
]

/// <summary>
/// Author: -
/// Remark: Not declaring role key mean open for all roles | empty array mean hide from all roles
/// </summary>
export const _AUTH_ROUTES = [
  {
    name: "LOGOHOUSE",
    path: WebUrl._DEFAULT,
    icon: <Icon as={MdHome} width='22px' height='22px' color='inherit' />,
    component: <Home />,
  },
  {
    name: "CART",
    path: WebUrl._CART,
    component: <Cart />,
  },
  {
    name: "PAYMENT_PROCESS",
    path: WebUrl._PAYMENT_PROCESS,
    component: <PaymentProcess />,
  },
  {
    name: "TRANSACTION_INIT",
    path: WebUrl._TRANSACTION_INIT,
    component: <TransactionInit />,
  },
  {
    name: "TRANSACTION_CALLBACK",
    path: WebUrl._TRANSACTION_CALLBACK,
    component: <TransactionCallback />,
  },
  {
    name: "TRANSACTION_CALLBACK",
    path: WebUrl._TRANSACTION_DIRECT_CALLBACK,
    component: <TransactionDirectCallback />,
  }
  ,
  {
    name: "CHECK_OUT",
    path: WebUrl._CHECK_OUT,
    component: <CheckOut />,
  }
];
