import React, { useEffect, useState, createContext } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from "react-redux";
import NonAuthLayout from './layouts/non-auth';
import AdminLayout from './layouts/admin';
import { stringIsNullOrEmpty, isObjectEmpty } from './common/util';
import { ConfigEnum } from './common/config';
import { Language, Currency } from './common/constant';
import LoadingScreen from 'components/loading-screen';
import CustomDialog from 'components/custom-dialog';
import CustomInputDialog from 'components/custom-input-dialog';
import { checkIsLoggedIn } from './application/action/auth_action';

export const ToggleContent = createContext();

/// <summary>
/// Author: -
/// </summary>
const App = () => {
    const { t, i18n } = useTranslation();
    const _IS_LOGGED_IN = useSelector((state) => state.authState.isLoggedIn);
    const _USER_DATA = useSelector((state) => state.authState.userData);
    var _dispatch = useDispatch();
    var _customDialogData = useSelector((state) => state.appState.customDialogData);
    var _customInputDialogData = useSelector((state) => state.appState.customInputDialogData);
	const [renderTopBar, setRenderTopBar] = useState(true);

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        (async () => {
            if (stringIsNullOrEmpty(localStorage.getItem(ConfigEnum._LANGUAGE))) {
                localStorage.setItem(ConfigEnum._LANGUAGE, Language._ENGLISH);
            }

            if (stringIsNullOrEmpty(localStorage.getItem(ConfigEnum._SELECTED_CURRENCY))) {
                await localStorage.setItem(ConfigEnum._SELECTED_CURRENCY, Currency._MYR);
                await localStorage.setItem(ConfigEnum._CURRENCY_RATE, 1);
            }
        })();
    }, []);

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        if (_IS_LOGGED_IN) {
            _dispatch(checkIsLoggedIn());
        }
    }, [_IS_LOGGED_IN]);

    /// <summary>
	/// Author: -
	/// </summary>
	const toggleTopBar = (open) => {
		setRenderTopBar(open);
	};

    return (
        <div>
            <ToggleContent.Provider value={{ renderTopBar, toggleTopBar }}>
                <LoadingScreen />
                <CustomDialog
                    isVisible={!isObjectEmpty(_customDialogData)}
                    success={_customDialogData.success}
                    title={_customDialogData.title}
                    content={_customDialogData.content}
                    onConfirm={_customDialogData.onConfirm}
                    onCancel={_customDialogData.onCancel}
                    confirmTxt={_customDialogData.confirmTxt}
                    cancelTxt={_customDialogData.cancelTxt}
                    disableHardwareBackPress={
                        _customDialogData.disableHardwareBackPress
                    }
                />
                <CustomInputDialog
                    isVisible={!isObjectEmpty(_customInputDialogData)}
                    rowId={_customInputDialogData?.rowId}
                    title={_customInputDialogData?.title}
                    content={_customInputDialogData?.content}
                    onConfirm={_customInputDialogData?.onConfirm}
                    onCancel={_customInputDialogData?.onCancel}
                    confirmTxt={_customInputDialogData?.confirmTxt}
                    cancelTxt={_customInputDialogData?.cancelTxt}
                    inputType={_customInputDialogData?.inputType}
                    isPassword={_customInputDialogData?.isPassword}
                    icon={_customInputDialogData?.icon}
                    isTextArea={_customInputDialogData?.isTextArea}
                    style={_customInputDialogData?.style}
                    isRequired={_customInputDialogData?.isRequired}
                    isManualHideAlert={_customInputDialogData?.isManualHideAlert}
                    options={_customInputDialogData?.options}
                    defaultValue={_customInputDialogData?.defaultValue}
                />
                {
                    _IS_LOGGED_IN ?
                        <AdminLayout /> :
                        <NonAuthLayout />
                }
            </ToggleContent.Provider>
        </div>
    )
}

const mapStateToProps = state => ({ ...state });

export default connect(mapStateToProps, null)(App);