import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { stringIsNullOrEmpty, createMultiPartFormBody, isObjectEmpty, formatCreditCardNumber, formatExpirationDate, formatCVC } from '../../../common/util';
import { ApiKey, RazerRequestMethod, RazerRequestType } from '../../../common/constant';
import { useForm, Controller } from "react-hook-form";
import ApiEngine from 'common/api-engine';
import {
    Box,
    Flex,
    HStack,
    Heading,
    Link,
    Stack,
    useColorModeValue as mode,
    Text,
    Button,
    FormControl,
    Container,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    SimpleGrid
} from '@chakra-ui/react'
import CustomInput from '../../../components/custom-input';
import Card from '../../../components/card/Card';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import Routes from '../../../common/api_routes';
import { useDispatch } from 'react-redux';
import { showCustomDialog } from '../../../application/action/app_action';

/// <summary>
/// Author: Saitama
/// </summary>
const CheckOut = () => {
    var _dispatch = useDispatch();
    var _location = useLocation();
    const _PARAMS = _location?.state;
    const { control, handleSubmit, errors, setValue, watch } = useForm();
    const [cardInfo, setCardInfo] = useState({
        number: '',
        expiry: '',
        cvc: '',
        name: '',
        focus: '',
    });

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const handleInputChange = (evt) => {
        let { name, value } = evt.target;

        if (name === "number") {
            value = formatCreditCardNumber(value);
        }
        else if (name === "expiry") {
            value = formatExpirationDate(value);
        }
        else if (name === "cvc") {
            value = formatCVC(value);
        }

        setCardInfo((prev) => ({ ...prev, [name]: value }));
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const handleInputFocus = (evt) => {
        setCardInfo((prev) => ({ ...prev, focus: evt.target.name }));
    }

    /// <summary>
    /// Author: Saitama
    /// Edit: YJ
    /// </summary>
    const submitForm = async (data, e) => {
        try {
            const [expMonth, expYear] = data.expiry.split("/");

            let params = {
                "grandTotal": _PARAMS.grandTotal,
                "items": _PARAMS.items,
                "sessionId": _PARAMS.sessionId,
                "cardHolderName": data.name,
                "cvc": data.cvc,
                "cardNumber": data.number.replace(/\s+/g, ""),
                "expMonth": expMonth,
                "expYear": "20" + expYear
            }

            const response = await ApiEngine.post(Routes._PLACE_ORDER, params);

            if (!response[ApiKey._API_SUCCESS_KEY]) {
                throw response[ApiKey._API_MESSAGE_KEY];
            }

            if (response[ApiKey._API_DATA_KEY]["isDirectRazer"]) {
                let txnData = response[ApiKey._API_DATA_KEY]["txnData"];

                let requestUrl = txnData["requestURL"];
                let requestMethod = txnData["requestMethod"];
                let requestType = txnData["requestType"];
                let requestData = txnData["requestData"];

                // auto submit form
                const form = document.createElement("form");
                form.method = requestMethod == RazerRequestMethod._POST ? "Post" : "Get";
                form.action = requestUrl.replace(/\\/g, "");

                const input = document.createElement("input");
                input.type = "hidden";
                input.name = "q";
                input.value = requestData.q.replace(/\\/g, "");

                form.appendChild(input);
                document.body.appendChild(form);

                form.submit(); 
            }
            else {
                window.location.href = response[ApiKey._API_DATA_KEY]["redirectUrl"];
            }
        }
        catch (err) {
            console.error(err);
            _dispatch(showCustomDialog({
                content: err
            }));
        }
    };

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Container>
                <Card
                    justifyContent='center'
                    align='center'
                    direction='column'
                    w='100%'
                    mb='0px'
                    bg='#F4F7FE'>
                    <Cards
                        number={cardInfo.number}
                        expiry={cardInfo.expiry}
                        cvc={cardInfo.cvc}
                        name={cardInfo.name}
                        focused={cardInfo.focus}
                    />
                    <FormControl mt={10}>
                        <form onSubmit={handleSubmit(submitForm)}>
                            <Controller
                                control={control}
                                name="number"
                                defaultValue={cardInfo.number}
                                render={({ onChange, value, name }) => (
                                    <CustomInput
                                        id={name}
                                        inputType='tel'
                                        onChange={(value) => {
                                            onChange(value);
                                            handleInputChange(value);
                                        }}
                                        onFocus={handleInputFocus}
                                        value={cardInfo.number}
                                        label={"CARD_NO"}
                                        placeHolder={"CARD_NO"}
                                        errors={errors}
                                        patternErrorTxt={'INVALID'}
                                    />
                                )}
                                rules={{
                                    required: true,
                                    pattern: {
                                        value: /^[\d ]{16,22}$/,
                                        message: "Card number must be at least 16",
                                    },
                                    message: "REQUIRED",
                                }}
                            />
                            <Controller
                                control={control}
                                name="name"
                                defaultValue={cardInfo.name}
                                render={({ onChange, value, name }) => (
                                    <CustomInput
                                        id={name}
                                        onChange={(value) => {
                                            onChange(value);
                                            handleInputChange(value);
                                        }}
                                        onFocus={handleInputFocus}
                                        value={value}
                                        label={"NAME"}
                                        placeHolder={"NAME"}
                                        errors={errors}
                                    />
                                )}
                                rules={{
                                    required: true,
                                    message: "REQUIRED",
                                }}
                            />
                            <Controller
                                control={control}
                                name="expiry"
                                defaultValue={cardInfo.expiry}
                                render={({ onChange, value, name }) => (
                                    <CustomInput
                                        id={name}
                                        inputType='tel'
                                        onChange={(value) => {

                                            onChange(value);
                                            handleInputChange(value);
                                        }}
                                        onFocus={handleInputFocus}
                                        value={cardInfo.expiry}
                                        label={"EXPIRATION_DATE"}
                                        placeHolder={"EXPIRATION_DATE"}
                                        errors={errors}
                                        patternErrorTxt={'INVALID'}

                                    />
                                )}
                                rules={{
                                    required: true,
                                    message: "REQUIRED",
                                    pattern: {
                                        value: /^\d\d\/\d\d$/,
                                        message: "Expiration date must be in MM/YY format",
                                    },
                                }}
                            />
                            <Controller
                                control={control}
                                name="cvc"
                                defaultValue={cardInfo.cvc}
                                render={({ onChange, value, name }) => (
                                    <CustomInput
                                        id={name}
                                        inputType='tel'
                                        onChange={(value) => {
                                            onChange(value);
                                            handleInputChange(value);
                                        }}
                                        onFocus={handleInputFocus}
                                        value={cardInfo.cvc}
                                        label={"CVC"}
                                        placeHolder={"CVC"}
                                        errors={errors}
                                        patternErrorTxt={'INVALID'}

                                    />
                                )}
                                rules={{
                                    required: true,
                                    message: "REQUIRED",
                                    pattern: {
                                        value: /^\d{3,4}$/, // Ensures 3-4 digits
                                        message: "CVC must be 3 or 4 digits",
                                    },
                                }}
                            />
                            {/* <Accordion allowToggle backgroundColor={'white'}>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as='span' flex='1' textAlign='left'>
                                                Billing Info
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={'20px'}>
                                            <Controller
                                                control={control}
                                                name="address1"
                                                defaultValue={''}
                                                render={({ onChange, value, name }) => (
                                                    <CustomInput
                                                        id={name}
                                                        onChange={(value) => {
                                                            onChange(value);
                                                        }}
                                                        value={value}
                                                        label={"ADDRESS_1"}
                                                        placeHolder={"ADDRESS_1"}
                                                        errors={errors}
                                                    />
                                                )}
                                                rules={{
                                                    required: true,
                                                    message: "REQUIRED",
                                                }}
                                            />
                                            <Controller
                                                control={control}
                                                name="address2"
                                                defaultValue={''}
                                                render={({ onChange, value, name }) => (
                                                    <CustomInput
                                                        id={name}
                                                        onChange={(value) => {
                                                            onChange(value);
                                                        }}
                                                        value={value}
                                                        label={"ADDRESS_2"}
                                                        placeHolder={"ADDRESS_2"}
                                                        errors={errors}
                                                    />
                                                )}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={'20px'}>
                                            <Controller
                                                control={control}
                                                name="city"
                                                defaultValue={''}
                                                render={({ onChange, value, name }) => (
                                                    <CustomInput
                                                        id={name}
                                                        onChange={(value) => {
                                                            onChange(value);
                                                        }}
                                                        value={value}
                                                        label={"CITY"}
                                                        placeHolder={"CITY"}
                                                        errors={errors}
                                                    />
                                                )}
                                                rules={{
                                                    required: true,
                                                    message: "REQUIRED",
                                                }}
                                            />
                                            <Controller
                                                control={control}
                                                name="state"
                                                defaultValue={''}
                                                render={({ onChange, value, name }) => (
                                                    <CustomInput
                                                        id={name}
                                                        onChange={(value) => {
                                                            onChange(value);
                                                        }}
                                                        value={value}
                                                        label={"STATE"}
                                                        placeHolder={"STATE"}
                                                        errors={errors}
                                                    />
                                                )}
                                                rules={{
                                                    required: true,
                                                    message: "REQUIRED",
                                                }}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={'20px'}>
                                            <Controller
                                                control={control}
                                                name="zipCode"
                                                defaultValue={''}
                                                render={({ onChange, value, name }) => (
                                                    <CustomInput
                                                        id={name}
                                                        inputType='number'
                                                        onChange={(value) => {
                                                            onChange(value);
                                                        }}
                                                        value={value}
                                                        label={"ZIPCODE"}
                                                        placeHolder={"ZIPCODE"}
                                                        errors={errors}
                                                        patternErrorTxt={'INVALID'}
                                                    />
                                                )}
                                                rules={{
                                                    required: true,
                                                    message: "REQUIRED",
                                                    pattern: {
                                                        value: /^\d{5}$/, 
                                                        message: "CVC must be 3 or 4 digits",
                                                    },
                                                }}
                                            />
                                            <Controller
                                                control={control}
                                                name="country"
                                                defaultValue={''}
                                                render={({ onChange, value, name }) => (
                                                    <CustomInput
                                                        id={name}
                                                        onChange={(value) => {
                                                            onChange(value);
                                                        }}
                                                        value={value}
                                                        label={"COUNTRY"}
                                                        placeHolder={"COUNTRY"}
                                                        errors={errors}
                                                    />
                                                )}
                                                rules={{
                                                    required: true,
                                                    message: "REQUIRED",
                                                }}
                                            />
                                        </SimpleGrid>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion> */}
                            <Flex gap='10px' mt='20px'>
                                <Button
                                    fontSize="sm"
                                    variant="brand"
                                    fontWeight="500"
                                    w="100%"
                                    h="50"
                                    type="submit">
                                    {`I'm ready to pay`}
                                </Button>
                            </Flex>
                        </form>
                    </FormControl>
                </Card>
            </Container>
        </Box>
    )
}

export default CheckOut;