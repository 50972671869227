import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Button, BreadcrumbLink, Flex, Link, Text, useColorModeValue } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import AdminNavbarLinks from '../../components/navbar/NavbarLinksAdmin';
import { useRouter } from "../../common/useRouter";
import { WebUrl } from "../../routes";
import { connect, useDispatch, useSelector } from "react-redux";
import { FaShoppingCart } from "react-icons/fa";
import { useLocation } from 'react-router-dom';
import CustomSelect from '../../components/custom-select';
import { Currency, _CURRENCY_OPTION, ApiKey } from '../../common/constant';
import { ConfigEnum } from '../../common/config';
import { stringIsNullOrEmpty } from '../../common/util';
import moment from 'moment';
import axios from 'axios';

/// <summary>
/// Author: -
/// </summary>
export default function NavbarHeader(props) {
	const { t } = useTranslation();
	const [scrolled, setScrolled] = useState(false);
	var _router = useRouter();
	const _LOCATION = useLocation();
	const _IS_LOGGED_IN = useSelector((state) => state.authState.isLoggedIn);
	const _USER_DATA = useSelector((state) => state.authState.userData);
	const [currency, setCurrency] = useState(localStorage.getItem(ConfigEnum._SELECTED_CURRENCY));

	const { secondary, message, brandText } = props;
	// Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
	let mainText = useColorModeValue('navy.700', 'white');
	let secondaryText = useColorModeValue('gray.700', 'white');
	let navbarPosition = 'fixed';
	let navbarFilter = 'none';
	let navbarBackdrop = 'blur(20px)';
	let navbarShadow = 'none';
	let navbarBg = useColorModeValue('rgba(244, 247, 254, 0.2)', 'rgba(11,20,55,0.5)');
	let navbarBorder = 'transparent';
	let secondaryMargin = '0px';
	let paddingX = '15px';
	let gap = '0px';

	/// <summary>
	/// Author: -
	/// </summary>
	useEffect(() => {
		window.addEventListener('scroll', changeNavbar);

		return () => {
			window.removeEventListener('scroll', changeNavbar);
		};
	});

	/// <summary>
	/// Author: Saitama
	/// </summary>
	useEffect(() => {
		(async () => {
			try {
				if (!stringIsNullOrEmpty(currency) && currency != Currency._MYR) {
					const responseJson = await axios.get('https://v6.exchangerate-api.com/v6/e8203f165ed192260d15a6b9/latest/MYR');
					let data = responseJson[ApiKey._API_DATA_KEY];

					if (data['result'] == 'success') {
						await localStorage.setItem(ConfigEnum._SELECTED_CURRENCY, currency);
						await localStorage.setItem(ConfigEnum._CURRENCY_RATE, data['conversion_rates'][currency]);
					}
				}
				else if (currency == Currency._MYR) {
					await localStorage.setItem(ConfigEnum._SELECTED_CURRENCY, Currency._MYR);
					await localStorage.setItem(ConfigEnum._CURRENCY_RATE, 1);
				}
			}
			catch (err) {

			}
		})();
	}, [currency])

	/// <summary>
	/// Author: -
	/// </summary>
	const changeNavbar = () => {
		if (window.scrollY > 1) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};

	/// <summary>
	/// Author: CK
	/// </summary>
	window.addEventListener("scroll", function () {
		const header = document.getElementById("header");
		if (window.scrollY > 50) { // Change background after scrolling 50px
			header.classList.add("scrolled");
		} else {
			header.classList.remove("scrolled");
		}
	});

	return (
		<Flex zIndex={99} id="header" className='header-container'>
			<Container>
				<Flex flexDir={'row'} justifyContent={'space-between'} alignItems={'center'}>
					<Link onClick={() => { _router.navigate(WebUrl._DEFAULT); }}><Text className="logo-text">LOGOHOUSE</Text></Link>
					{
						_IS_LOGGED_IN ?
							<Box ms='auto' w={{ sm: '100%', md: 'unset' }}>
								<Flex flexDir={'row'} alignItems={'center'}>
									<Link mr="10px" onClick={() => { _router.navigate(WebUrl._CART); }}><FaShoppingCart color={'#3F37C9'} fontSize={'22px'} /></Link>
									<CustomSelect
										onChange={(e) => setCurrency(e.target.value)}
										value={currency}
										options={_CURRENCY_OPTION}
										style={{ mb: '10px', mr: '5px', minWidth: 'max-content' }}
									/>
									<AdminNavbarLinks
										onOpen={props.onOpen}
										logoText={props.logoText}
										secondary={props.secondary}
										fixed={props.fixed}
										scrolled={scrolled}
									/>
								</Flex>

							</Box> :
							!_LOCATION.pathname.includes("login") ?
								<Button className="sign-btn btn-grad" onClick={() => { _router.navigate(WebUrl._LOGIN); }}>Login</Button> : <></>
					}
				</Flex>
			</Container>
		</Flex>
	);
}

NavbarHeader.propTypes = {
	brandText: PropTypes.string,
	variant: PropTypes.string,
	secondary: PropTypes.bool,
	fixed: PropTypes.bool,
	onOpen: PropTypes.func
};
