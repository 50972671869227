export const _WEB_VERSION = '1.0.0';
export const _VERSION_BUILD_NUMBER = '1';

/// <summary>
/// Author: -
/// </summary>
export class SessionKey {
  static _ROUTE_TO_PROCEED = "routeToProceed";
}

/// <summary>
/// Author: -
/// </summary>
export class JwtSession {
  static _CLIENT_ID = "H5W7B6IDOLCNJKGXPU29";
  static _CLIENT_SECRET = "1QZFECP5O3MXHVWUKSJBIA42D";
}

/// <summary>
/// Author: -
/// </summary>
export class ApiKey {
  static _API_MESSAGE_KEY = 'message';
  static _API_DATA_KEY = 'data';
  static _API_STATUS_KEY = 'status';
  static _API_SUCCESS_KEY = 'success';
  static _API_REQUEST_KEY = 'request';
  static _API_RESPONSE_URL_KEY = 'responseURL';
  static _API_CODE_KEY = 'code';
  static _API_ERROR_KEY = 'error';
  static _API_TOTAL_AMOUNT_KEY = 'totalAmount';
  static _API_COUNT_KEY = 'totalCount';

  static _API_CONTENT_TYPE = 'content-type';
  static _API_ACCEPT = 'Accept';
  static _API_APPLICATION_JSON = 'application/json';
  static _API_XML_HTTP_REQUEST_KEY = 'XMLHttpRequest';
  static _API_FORM_URLENCODED = 'application/x-www-form-urlencoded';
  static _API_MULTIPART_FORM = 'multipart/form-data';
  static _API_POST = 'POST';
  static _API_GET = 'GET';

  static _API_STATUS_OK = 200;
  static _API_APP_VERSION_NOT_COMPATIBLE = 406;
  static _API_INVALID_AUTHORIZATION = 203;
  static _API_STATUS_UNAUTHORIZED = 401;
}

/// <summary>
/// Author: -
/// </summary>
export class InputValidationKey {
  static _REQUIRED_KEY = 'required';
  static _MAX_LENGTH_KEY = 'maxLength';
  static _MIN_LENGTH_KEY = 'minLength';
  static _MAX_KEY = 'max';
  static _MIN_KEY = 'min';
  static _PATTERN = 'pattern';
}

/// <summary>
/// Author : -
/// </summary>
export class Language {
  static _CHINESE = 'zh';
  static _ENGLISH = 'en';
  static _BAHASA_MELAYU = 'ms';
}

/// <summary>
/// Author: -
/// </summary>
export const _LANGUAGE_OPTION = [
  {
    label: 'English',
    prefix: 'EN',
    value: Language._ENGLISH
  },
  {
    label: '简体中文',
    prefix: '中文',
    value: Language._CHINESE
  },
  {
    label: 'Bahasa Melayu',
    prefix: 'BM',
    value: Language._BAHASA_MELAYU
  }
];

/// <summary>
/// Author: -
/// </summary>
export class RegexPattern {
  static _EMAIL_VALIDATION = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  static _ZIP_CODE_VALIDATION = /^\d{5}$/;
  static _TWO_DECIMAL_VALIDATION = /^\d+(\.\d{1,2})?$/;
}

/// <summary>
/// Author : -
/// </summary>
export class Role {
  static _SUPERADMIN = 1;
  static _COMPANY = 2;
  static _CUSTOMER = 3;
}

/// <summary>
/// Author : -
/// </summary>
export class OrderStatus {
  static _PENDING = 1;
  static _SUCCESS = 2;
  static _FAILED = -1;
}

/// <summary>
/// Author: CK
/// </summary>
export const _HOME_DEFAULT_IMGAGES = [
  {
    src: require('./../../src/assets/img/sample1.jpg')
  },
  {
    src: require('./../../src/assets/img/sample2.jpg')
  },
  {
    src: require('./../../src/assets/img/sample3.jpg')
  },
  {
    src: require('./../../src/assets/img/sample4.jpg')
  },
  {
    src: require('./../../src/assets/img/sample5.jpg')
  },
  {
    src: require('./../../src/assets/img/sample6.jpg')
  },
  {
    src: require('./../../src/assets/img/sample7.jpg')
  },
  {
    src: require('./../../src/assets/img/sample8.jpg')
  }
];

/// <summary>
/// Author : -
/// </summary>
export class RazerRequestType {
  static _REDIRECT = "REDIRECT";
  static _POPUP = "POPUP";
}

/// <summary>
/// Author : -
/// </summary>
export class RazerRequestMethod {
  static _POST = "POST";
  static _GET = "GET";
}

/// Author : Saitama
/// </summary>
export class Currency {
  static _MYR = 'MYR';
  static _USD = 'USD';
}

/// <summary>
/// Author: Saitama
/// </summary>
export const _CURRENCY_OPTION = [
  {
    label: 'MYR',
    value: Currency._MYR
  },
  {
    label: 'USD',
    value: Currency._USD
  }
];
